import { Action, createReducer, on } from '@ngrx/store';
import * as BundleActions from './bundle.actions';
import { BundleReviewModel } from '@lms/shared/models';

export class BundleStoreState {
  public reviews: Readonly<Array<BundleReviewModel>> = [] as BundleReviewModel[];
}

export const initialState: Readonly<BundleStoreState> = {
  reviews: [] as BundleReviewModel[],
};

const bundleReducer = createReducer(
  initialState,
  on(BundleActions.setBundleReviews, (state, action) => {
    return { ...state, reviews: action.reviews };
  }),
);

export function reducer(state: BundleStoreState | undefined, action: Action): BundleStoreState {
  return bundleReducer(state, action);
}
