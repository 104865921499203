import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { getBundleReviews, setBundleReviews } from './bundle.actions';
import { BundleReviewService } from '../services/bundle-review.service';
import { BundleReviewModel } from '@lms/shared/models';

@Injectable({
  providedIn: 'root',
})
export class BundleEffects {
  loadBundleReviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBundleReviews),
      mergeMap(action => this.bundleReviewsService.getLatestReviews(action.bundleUrl)),
      map((reviews: BundleReviewModel[]) => setBundleReviews({ reviews })),
    ),
  );

  constructor(
    private actions$: Actions,
    private bundleReviewsService: BundleReviewService,
  ) {}
}
