import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { ApiResponseModel, FreeLessonModel } from '@lms/shared/models';
import { pluck } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LessonService {
  constructor(private apiService: ApiService) {}

  freeListing(courseId: number): Observable<FreeLessonModel[]> {
    const params = new HttpParams().set('courseId', courseId.toString());

    return this.apiService.executeGet<ApiResponseModel<any>>('lessons/free_listing', params).pipe(pluck('data', 'freeLessons'));
  }
}
