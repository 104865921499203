import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  public enabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showOnMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showTopBar$ = new BehaviorSubject(false);
  lastEnteredUrl$ = new BehaviorSubject('');
}
