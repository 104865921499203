import { Inject, Injectable } from '@angular/core';
import { CurrentUserStorage } from '@lms/shared/storages';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

@Injectable({
  providedIn: 'root',
})
export class TypeformLinkService {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
    private currentUserStorage: CurrentUserStorage,
  ) {}

  public getLink(surveyType: string = 'welcome'): string {
    if (!this.environment.welcomeSurveyUrl) return '';
    const user = this.currentUserStorage.getCurrentUser();
    return new URL(this.environment.welcomeSurveyUrl) + `#email=${user?.email}&user_id=${user?.id}&survey=${surveyType}&user=${user?.id}`;
  }
}
