import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
  ApiResponseModel,
  BundleDashboardListingModel,
  BundleListingTypeEnum,
  BundleTypeEnum,
  CourseDashboardListingModel,
  CourseEnrollmentSourceEnum,
  CourseListingFilterModel,
  CourseListingModel,
  CourseListingTypeEnum,
} from '@lms/shared/models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(private apiService: ApiService) {}

  listing(filter: CourseListingFilterModel): Observable<ApiResponseModel<CourseListingModel>> {
    let params = new HttpParams().set('pageNumber', filter.pageNumber?.toString() || '').set('pageSize', filter.pageSize?.toString() || '');
    if (typeof filter.query !== 'undefined' && filter.query !== null) {
      params = params.set('query', filter.query.toString());
    }
    if (typeof filter.category !== 'undefined' && filter.category !== null) {
      params = params.set('category', filter.category.toString());
    }
    return this.apiService.executeGet<ApiResponseModel<CourseListingModel>>('my_courses/listing', params);
  }

  getCourses(completedOnly: boolean = false): Observable<ApiResponseModel<CourseDashboardListingModel>> {
    const params = new HttpParams()
      .set('completedOnly', completedOnly ? CourseListingTypeEnum.COMPLETED : CourseListingTypeEnum.ALL)
      .set('source', CourseEnrollmentSourceEnum.SINGLE_OR_NOT_PROGRAM)
      .set('pageNumber', '1')
      .set('pageSize', '10000') // without pagination for now
      .set('sort', 'recentlyViewed_desc');
    return this.apiService.executeGet<ApiResponseModel<CourseDashboardListingModel>>('my_courses/listing', params);
  }

  getPrograms(completedOnly: boolean = false): Observable<ApiResponseModel<BundleDashboardListingModel>> {
    const params = new HttpParams()
      .set('completedOnly', completedOnly ? BundleListingTypeEnum.COMPLETED : BundleListingTypeEnum.ALL)
      .set('sort', 'enrollmentCreated_desc')
      .set('type', BundleTypeEnum.PROGRAM_TYPE);
    return this.apiService.executeGet<ApiResponseModel<BundleDashboardListingModel>>('bundles/my/listing', params);
  }
}
