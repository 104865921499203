import { Inject, Injectable } from '@angular/core';
import { CurrentUserStorage } from '@lms/shared/storages';
import { CookieService } from 'ngx-cookie-service';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

const USER_COOKIE_PATH = 'student-info';
const USER_JUST_REGISTERED_COOKIE_PATH = 'user_was_created';
const USER_JUST_LOGGED_COOKIE_PATH = 'user_just_logged';

@Injectable({
  providedIn: 'root',
})
export class LoginCookieService {
  constructor(
    private currentUserStorage: CurrentUserStorage,
    private cookieService: CookieService,
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
  ) {}

  readUserFromCookie(): void {
    try {
      this.currentUserStorage.twinLmsUser = JSON.parse(atob(this.cookieService.get(USER_COOKIE_PATH)));
    } catch (e) {
      return;
    }
  }

  readUserIsJustRegisteredFromCookie(): boolean {
    // if user just registered we will get 1 inside cookie , if not we will get 0
    try {
      return !!JSON.parse(this.cookieService.get(USER_JUST_REGISTERED_COOKIE_PATH));
    } catch (e) {
      return false;
    }
  }

  deleteJustRegisteredUserCookie(): void {
    try {
      const domain = this.environment.cookieDomain;
      this.cookieService.delete(USER_JUST_REGISTERED_COOKIE_PATH, '/', domain);
    } catch (e) {
      return;
    }
  }

  readUserIsJustLoggedFromCookie(): boolean {
    try {
      return !!JSON.parse(this.cookieService.get(USER_JUST_LOGGED_COOKIE_PATH));
    } catch (e) {
      return false;
    }
  }

  deleteJustLoggedUserCookie(): void {
    try {
      const domain = this.environment.cookieDomain;
      this.cookieService.delete(USER_JUST_LOGGED_COOKIE_PATH, '/', domain);
    } catch (e) {
      return;
    }
  }
}
