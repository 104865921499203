import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
  ) {}

  init(): void {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable));
    const everyMinute$ = interval(60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyMinute$);

    everySixHoursOnceAppIsStable$.subscribe(() => {
      this.updates
        .checkForUpdate()
        .then(() => {
          console.warn('Checking for app updates...');
        })
        .catch(err => {
          console.warn('Error when checking for update', err);
        });
    });

    this.updates.versionUpdates.subscribe(data => {
      console.warn(data);
      if (confirm('Application version updated. Press Ok to reload application')) {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });

    this.updates.unrecoverable.subscribe(event => {
      alert(`An error occurred that we cannot recover from:\n${event.reason}\n\n` + 'Please reload the page.');
    });
  }

  unregisterAll(): void {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
}
