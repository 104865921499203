import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {
  AdminBundleAutocompleteListingModel,
  AdminBundleAutocompleteModel,
  AdminBundleBuilderModel,
  AdminBundleListingModel,
  AdminBundleModel,
  AdminBundleShowModel,
  ApiResponseModel,
  ListingFilter,
} from '@lms/shared/models';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { pluck } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdminBundleService {
  constructor(private apiService: ApiService) {}

  listing(filter: BundleListingFilter): Observable<ApiResponseModel<AdminBundleListingModel>> {
    let params = new HttpParams()
      .set('pageNumber', (filter.pageNumber || '').toString())
      .set('pageSize', (filter.pageSize || '').toString());
    if (typeof filter.sort !== 'undefined' && filter.sort !== null) {
      params = params.set('sort', filter.sort.toString());
    }
    if (typeof filter.query !== 'undefined' && filter.query !== null) {
      params = params.set('query', (filter?.query || '').toString());
    }
    if (typeof filter.status !== 'undefined' && filter.status !== null) {
      params = params.set('status', filter.status.toString());
    }
    return this.apiService.executeGet<ApiResponseModel<AdminBundleListingModel>>('admin/bundles/listing', params);
  }

  getBundle(id: number): Observable<AdminBundleModel> {
    const params = new HttpParams().set('id', id.toString());
    return this.apiService.executeGet<ApiResponseModel<AdminBundleShowModel>>('admin/bundles/show', params).pipe(pluck('data', 'bundle'));
  }

  getBundleForBuilder(id: number): Observable<AdminBundleBuilderModel> {
    return this.apiService.executeGet<ApiResponseModel<AdminBundleBuilderModel>>(`admin/bundles/${id}/builder`).pipe(pluck('data'));
  }

  autocompleteListing(): Observable<AdminBundleAutocompleteModel[]> {
    return this.apiService
      .executeGet<ApiResponseModel<AdminBundleAutocompleteListingModel>>('admin/bundles/autocomplete_listing')
      .pipe(pluck('data', 'bundles'));
  }

  programAutocompleteListing(): Observable<AdminBundleAutocompleteModel[]> {
    return this.apiService
      .executeGet<ApiResponseModel<AdminBundleAutocompleteListingModel>>('bundles/programs/autocomplete_listing')
      .pipe(pluck('data', 'bundles'));
  }
}

export interface BundleListingFilter extends ListingFilter {
  status: string | null;
}
