import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ApiResponseModel, UploadFileCreateModel } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class ImageService {
  constructor(private apiService: ApiService) {}

  createImage(fileType: string, image: File): Observable<ApiResponseModel<UploadFileCreateModel>> {
    const params: FormData = new FormData();
    params.append('target', fileType);
    params.append('file', image, image.name);
    return this.apiService.executePost<ApiResponseModel<UploadFileCreateModel>>('images/create', params, true);
  }

  deleteImage(uuid: string): Observable<ApiResponseModel<null>> {
    return this.apiService.executeDelete<ApiResponseModel<null>>('images/delete', { uuid });
  }
}
