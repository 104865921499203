import { inject, Injectable } from '@angular/core';
import {
  ApiResponseModel,
  ArticleListingAbstractListingModel,
  ArticleListingModel,
  ListingFilter,
  ENVIRONMENT_TOKEN,
  TopicListingModel,
} from '@lms/shared/models';
import { catchError, Observable, of } from 'rxjs';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ArticleService {
  private http = inject(HttpClient);
  private handler = inject(HttpBackend);
  private env = inject(ENVIRONMENT_TOKEN);

  listing(filter: ListingFilter): Observable<ApiResponseModel<ArticleListingAbstractListingModel>> {
    this.http = new HttpClient(this.handler);

    let params = new HttpParams()
      .set('page', filter.pageNumber?.toString() || '')
      .set('page_size', filter.pageSize?.toString())
      .set('type', filter.type?.toString() || '');

    if (typeof filter.query !== 'undefined' && filter.query !== null && filter.query.length > 0) {
      params = params.set('s', filter.query.toString());
    }

    if (typeof filter.category !== 'undefined' && filter.category !== null) {
      params = params.set('category', filter.category.toString());
    }

    const url = this.env.elasticSearchEndpoint ? this.env.elasticSearchEndpoint + '/articles' : '/elastic/articles';
    return this.http.get<ArticleListingModel>(url, { params }).pipe(
      map((response: ArticleListingModel) => this.mapArticlesListingModel(response)),
      catchError(() => {
        return of({
          data: {
            articles: [],
            itemsList: 0,
          },
        } as any);
      }),
    );
  }

  private mapArticlesListingModel(response: ArticleListingModel): ApiResponseModel<ArticleListingAbstractListingModel> {
    return {
      status: { code: '', desc: '', warnings: [] },
      data: {
        ...response,
        itemsList: response.articles,
        totalItems: response.total,
      },
    };
  }

  prepareArticleTopics(idArr: string[], topics: TopicListingModel[]): string[] {
    if (!idArr || !topics) {
      return [];
    }
    const result = idArr.map(id => {
      const findId = topics.find(topic => topic.marketingCoreCategoryId.toString() === id);
      return findId ? findId.name : '';
    });
    return result.filter(item => item).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }
}
