import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import {
  ApiResponseModel,
  CourseCurriculumShowModel,
  CourseListingAbstractListingModel,
  CourseListingFilterModel,
  CourseListingModel,
  CourseSearchModel,
  CourseShowModel,
  Environment,
  ENVIRONMENT_TOKEN,
  PricingPlanShowModel,
  SearchResults,
} from '@lms/shared/models';
import { Router, UrlSerializer } from '@angular/router';
import { map, pluck } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CourseService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private urlSerializer: UrlSerializer,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
  ) {}

  listing(filter: CourseListingFilterModel): Observable<ApiResponseModel<CourseListingAbstractListingModel>> {
    let params = new HttpParams()
      .set('pageNumber', filter.pageNumber?.toString() || '')
      .set('sort', filter.sort?.toString() || '')
      .set('pageSize', filter.pageSize?.toString() || '');
    if (typeof filter.query !== 'undefined' && filter.query !== null) {
      params = params.set('query', filter.query.toString());
    }
    if (typeof filter.category !== 'undefined' && filter.category !== null) {
      params = params.set('category', filter.category.toString());
    }
    if (typeof filter.level !== 'undefined' && filter.level !== null) {
      params = params.set('level', filter.level?.toString());
    }
    if (typeof filter.programId !== 'undefined' && filter.programId !== null) {
      params = params.set('programId', filter.programId?.toString());
    }
    return this.apiService
      .executeGet<ApiResponseModel<CourseListingModel>>('courses/listing', params)
      .pipe(map(data => this.mapCourseListingModel(data)));
  }

  getCourseByUrl(url: string): Observable<ApiResponseModel<CourseShowModel>> {
    const params = new HttpParams().set('url', url);
    return this.apiService.executeGet<ApiResponseModel<CourseShowModel>>('courses/show', params);
  }

  getCoursePricingPlan(url: string, pricingPlanId: null | string): Observable<ApiResponseModel<PricingPlanShowModel>> {
    let params = new HttpParams().set('courseUrl', url);
    if (pricingPlanId) {
      params = params.set('pricingPlanId', pricingPlanId + '');
    }
    return this.apiService.executeGet<ApiResponseModel<PricingPlanShowModel>>('courses/pricing_plans', params);
  }

  getCurriculum(courseId: number): Observable<ApiResponseModel<CourseCurriculumShowModel>> {
    const params = new HttpParams().set('courseId', courseId.toString());
    return this.apiService.executeGet<ApiResponseModel<CourseCurriculumShowModel>>('courses/curriculum/show', params);
  }

  getPopular(): Observable<CourseSearchModel[]> {
    return this.apiService.executeGet<ApiResponseModel<SearchResults>>('courses/popular/listing').pipe(pluck('data', 'courses'));
  }

  private mapCourseListingModel(response: ApiResponseModel<CourseListingModel>): ApiResponseModel<CourseListingAbstractListingModel> {
    return {
      status: response.status,
      data: {
        ...response.data,
        itemsList: response.data.courses,
      },
    };
  }
}
