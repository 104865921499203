import { Injectable } from '@angular/core';
import { BundleModel, BundleReviewModel } from '@lms/shared/models';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BundleSelectors } from '../../store/bundle.selectors';
import { AbstractControl } from '@angular/forms';
import { ApiService } from '@lms/shared/services/api-services';

@Injectable({ providedIn: 'root' })
export class BundleReviewFacadeService {
  reviewsToShow = 6;
  totalReviews!: number;

  reviews$: Observable<Readonly<BundleReviewModel[]>> = this.bundleSelectors.bundleReviews$.pipe(
    map(reviews => {
      this.totalReviews = reviews.length;
      return reviews.slice(0, this.reviewsToShow);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  sendFeedback(bundle: BundleModel, form: AbstractControl): Observable<void> {
    const params = {
      bundleId: bundle.id,
      ...form.value,
    };
    return this.apiService.executePost('bundle_reviews/create', params, false, form);
  }

  constructor(
    private bundleSelectors: BundleSelectors,
    private apiService: ApiService,
  ) {}
}
