import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from './logger-services';
import { Router } from '@angular/router';

type ErrorLog = {
  name: any;
  message: any;
  stack: any;
  originalError: any;
};

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private logger: LoggerService,
    private router: Router,
  ) {}

  handleError(error: any): void {
    this.logger.error('Uncaught exception', error instanceof Error ? this.buildErrorLog(error) : error);

    if (error.rejection?.status === 403) {
      this.router.navigate(['/error/404']);
    }

    if (error.status === 503) {
      this.router.navigate(['/error/back-soon']);
    }
  }

  private buildErrorLog(error: Error): ErrorLog {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack,
      originalError: error,
    };
  }
}
