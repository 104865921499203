import { Inject, Injectable } from '@angular/core';
import { ThirdPartyLoaderService } from './third-party-loader.service';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

@Injectable({
  providedIn: 'root',
})
export class CookieyesService {
  constructor(
    private thirdPartyLoaderService: ThirdPartyLoaderService,
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
  ) {}

  run(): void {
    if (this.environment.cookieyesId) {
      this.thirdPartyLoaderService.loadExternalScript(
        `https://cdn-cookieyes.com/client_data/${this.environment.cookieyesId}/script.js`,
        'cookieyes',
      );
    }
  }
}
