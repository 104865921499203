import { Injectable } from '@angular/core';
import { ApiService } from '@lms/shared/services/api-services';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiResponseModel, BundleReviewListingModel, BundleReviewModel } from '@lms/shared/models';
import { pluck } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BundleReviewService {
  constructor(private apiService: ApiService) {}

  getLatestReviews(bundleUrl: string): Observable<BundleReviewModel[]> {
    const params = new HttpParams().set('bundleUrl', bundleUrl.toString()).set('limit', (40).toString());
    return this.apiService
      .executeGet<ApiResponseModel<BundleReviewListingModel>>('bundle_reviews/latest_listing', params)
      .pipe(pluck('data', 'reviews'));
  }
}
