import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, pluck } from 'rxjs';
import { ApiResponseModel, CountryListingModel } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class GeoService {
  constructor(private apiService: ApiService) {}

  countriesListing(): Observable<CountryListingModel> {
    return this.apiService.executeGet<ApiResponseModel<CountryListingModel>>('countries/listing').pipe(pluck('data'));
  }
}
