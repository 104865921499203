import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AppTitleService extends Title {
  private static prepareTitle(pageName: string, resourceName: string | undefined): string {
    if (resourceName) {
      return pageName + ' ' + resourceName;
    }
    return pageName;
  }

  setTitle(newTitle: string): void {
    if (newTitle) {
      super.setTitle(newTitle + ' | CFI');
    }
  }

  setCourseTitle(courseName: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('', courseName));
  }

  setEditBundleTitle(bundleName: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('Edit Bundle', bundleName));
  }

  setEditCourseTitle(courseName: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('Edit Course', courseName));
  }

  setEditCategoryTitle(categoryName: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('Edit Category', categoryName));
  }

  setEditInstructorTitle(firstname: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('Edit Instructor', firstname));
  }

  setEditAudioTitle(audioName: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('Edit Audio', audioName));
  }

  setEditPdfTitle(pdfName: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('Edit PDF', pdfName));
  }

  setEditVideoTitle(videoName: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('Edit Video', videoName));
  }

  setEditUserGroupTitle(groupName: string | undefined): void {
    this.setTitle(AppTitleService.prepareTitle('Edit User Group', groupName));
  }
}
