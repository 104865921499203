import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BodyTagService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setBodyClass(className: string): void {
    this.document.body.classList.add(className);
  }

  removeBodyClass(className: string): void {
    this.document.body.classList.remove(className);
  }

  setBodyAttribute(name: string, value: string): void {
    this.document.body.setAttribute(name, value);
  }

  removeBodyAttribute(name: string): void {
    this.document.body.removeAttribute(name);
  }
}
