import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ApiResponseModel, ReceiptModel, ReceiptModelWithUserInfo } from '@lms/shared/models';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ReceiptService {
  constructor(private apiService: ApiService) {}

  getReceiptList(): Observable<ReceiptModel[]> {
    return this.apiService.executeGet<ApiResponseModel<ReceiptModel[]>>('users/receipts').pipe(map(res => res.data));
  }

  receiptWithUserInfo(orderUuid: string, invoiceId: string, exportedToMaxio: boolean): Observable<ReceiptModelWithUserInfo | any> {
    let params = new HttpParams().set('orderUuid', orderUuid).set('exportedToMaxio', exportedToMaxio ? '1' : '0');
    if (invoiceId !== 'null') {
      params = params.set('invoiceId', invoiceId);
    }
    return this.apiService.executeGet<ApiResponseModel<ReceiptModelWithUserInfo>>('users/get_receipt', params).pipe(map(res => res.data));
  }

  updateBillingAddress(
    zip?: string | null,
    country?: string | null,
    stateCode?: string | null,
    regionValue?: string | null,
    city?: string | null,
    addressLine?: string | null,
    form?: AbstractControl,
  ) {
    const body = {
      zip,
      country,
      city,
      addressLine,
      region: regionValue,
      state: stateCode || null,
    };
    return this.apiService.executePost<ApiResponseModel<any>>('users/update_billing_address', body, false, form).pipe(map(res => res.data));
  }
}
