import { Injectable } from '@angular/core';
import { ApiResponseModel, CareerMapModel } from '@lms/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class CareerMapService {
  constructor(private apiService: ApiService) {}

  getCareerMap(): Observable<ApiResponseModel<CareerMapModel>> {
    return this.apiService.executeGet<ApiResponseModel<CareerMapModel>>('marketing_core/career_map');
  }
}
