import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BundleStoreState, getBundleReviews } from '@lms/dashboard-ui/bundle';
import { Observable, of } from 'rxjs';

@Injectable()
export class BundleResolverService {
  constructor(
    private router: Router,
    private store: Store<BundleStoreState>,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const bundleUrl = route?.params?.programUrl;
    if (bundleUrl) {
      this.store.dispatch(getBundleReviews({ bundleUrl }));
    }
    return of(null);
  }
}
