import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { ElementRef, Inject, inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ApiResponseModel,
  BundleModel,
  CourseSearchModel,
  Entry,
  Environment,
  ENVIRONMENT_TOKEN,
  SearchAutoComplete,
  SearchCard,
  SearchTermModel,
  SearchV2,
  SearchV2Raw,
} from '@lms/shared/models';
import { CourseService } from '@lms/shared/services/api-services';
import { deepCopy } from '@lms/shared/utils';
import {
  BehaviorSubject,
  debounceTime,
  finalize,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { B2bSearchMappingService, CurrentUserStorage } from '@lms/shared/storages';
import { SearchProgram } from '@lms/shared/models';

const STORAGE_SEARCH = 'recentSearches';

const DEFAULT_DEBOUNCE_TIME = 1000;

const COURSE_CATEGORY_FREE = 'free';

export type FormValueType =
  | {
      [key: string]: any;
      term: string;
    }
  | string;

export function getTermFromFormValueType(data: FormValueType): string {
  return typeof data === 'object' ? data?.term?.trim().toUpperCase() || '' : data.trim().toUpperCase();
}

@Injectable({ providedIn: 'root' })
export class SearchCoursesService {
  public focused$$ = new BehaviorSubject<boolean | null>(false);
  public focused$ = this.focused$$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  public bundle$$ = new BehaviorSubject<SearchProgram[] | null>(null);
  public backUrl: string = '';

  public form = new FormGroup({
    term: new FormControl<string>('', { nonNullable: true }),
  });

  public termControl = this.form.get('term');

  public inputEl!: ElementRef;
  public serviceInit = false;
  autocomplete$ = new Subject<Entry[]>();
  http = inject(HttpClient);
  private searchResults$$ = new Subject<SearchV2 | null>();
  public searchResults$: Observable<SearchV2 | null> = this.searchResults$$
    .asObservable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  private searchResultsLoading$$ = new Subject<boolean | null>();
  public searchResultsLoading$ = this.searchResultsLoading$$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  private recentSearches: Set<string> = new Set();
  private recentSearches$$ = new BehaviorSubject<string[]>([]);
  public recentSearches$: Observable<string[]> = this.recentSearches$$.asObservable();
  private subs = new Subscription();
  handler = inject(HttpBackend);

  constructor(
    private b2bSearchMappingService: B2bSearchMappingService,
    private courseService: CourseService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private currentUser: CurrentUserStorage,
    @Inject(ENVIRONMENT_TOKEN) public env: Environment,
  ) {}

  public init(): void {
    if (!this.serviceInit) {
      this.resetResults();

      this.form.valueChanges
        .pipe(
          debounceTime(DEFAULT_DEBOUNCE_TIME),
          switchMap(data => {
            return this.getAutoComplete(data.term || '');
          }),
          withLatestFrom(this.currentUser.isB2BUser$),
        )
        .subscribe(([data, isB2B]) => {
          this.b2bSearchMappingService.getEnrolledBundleLinks();
          const map = this.b2bSearchMappingService.b2cToB2bMap;
          data = data.map((el: Entry) => {
            if (map[el.uuid] && isB2B) {
              el.firstPartyUrl = true;
              const bundleLink = map[el.uuid];
              el.url = '/' + (bundleLink.B2BBundleUrl ?? bundleLink.B2CBundleUrl);
              el.uuid = bundleLink.B2BBundleUuid ?? bundleLink.B2CBundleUuid;
            }
            return el;
          });
          this.autocomplete$.next(data);
          // this.navigateToSearch({ term: data?.term }, true);
        });
    }
    this.loadSearches();
    this.serviceInit = true;
  }

  public onDestroy(): void {
    this.subs.unsubscribe();
    this.serviceInit = false;
    this.searchCoursesHandler('');
    this.resetSearchTerm();
  }

  public navigateToSearch(queryParams?: SearchTermModel | null, replaceUrl?: boolean): void {
    this.router.navigate(['search'], { queryParams: queryParams, replaceUrl: replaceUrl });
  }

  public resetSearchTerm(): void {
    if (!this.termControl) {
      return;
    }

    this.termControl.setValue('');
    this.resetResults();
  }

  public resetResults(): void {
    this.searchResults$$.next(null);
    this.bundle$$.next(null);
  }

  public searchCourses(term: string): Observable<SearchV2 | null> {
    if (!this.termControl?.value) return of(null);
    if (!term || 2 > this.termControl?.value?.length) {
      this.searchResults$$.next(null);
      return of({} as SearchV2);
    }

    this.setRecentSearches(this.termControl?.value);

    this.searchResultsLoading$$.next(true);
    return this.currentUser.isB2BUser$.pipe(
      switchMap(() => this.searchByTerm(term)),
      withLatestFrom(this.currentUser.isB2BUser$),
      tap(([searchResults, isB2BUser]) => {
        this.searchResults$$.next(this.isSearchResultsEmpty(searchResults) ? null : searchResults);

        if (!searchResults) return;
        this.b2bSearchMappingService.getEnrolledBundleLinks();
        const map = this.b2bSearchMappingService.b2cToB2bMap;
        searchResults.search_cards = searchResults?.search_cards.map((el: SearchCard) => {
          if (map[el.uuid] && isB2BUser) {
            el.firstPartyUrl = true;
            const bundleLink = map[el.uuid];
            el.card.url = '/' + (bundleLink.B2BBundleUrl ?? bundleLink.B2CBundleUrl);
            el.uuid = bundleLink.B2BBundleUuid ?? bundleLink.B2CBundleUuid;
          }
          return el;
        });
        const searchPrograms = searchResults?.search_cards.filter(
          value => !(value.card?.settings?.course_category_id === COURSE_CATEGORY_FREE),
        );
        this.bundle$$.next(searchPrograms as SearchProgram[]);
      }),
      map(([searchResults, isB2BUser]) => searchResults),
      finalize(() => this.searchResultsLoading$$.next(false)),
    );
  }

  public setFocus(focus: boolean): void {
    this.focused$$.next(focus);

    if (!focus && this.termControl?.value) {
      this.termControl.reset(this.termControl.value);
    }
  }

  public getPopularCourses(): Observable<CourseSearchModel[]> {
    return this.courseService.getPopular();
  }

  public searchCoursesHandler(data: FormValueType): void {
    const term: string = getTermFromFormValueType(data);
    this.bundle$$.next(null);

    this.searchCourses(term).subscribe();
  }

  getAutoComplete(term: string): Observable<Entry[]> {
    this.http = new HttpClient(this.handler);
    const excludeParameter = this.currentUser.isB2BUser ? this.b2bSearchMappingService.excludedB2cUuids : [];
    const params = new HttpParams()
      .set('query', term)
      .set('prev_query', '')
      .set('limit', 10)
      .set('page', 1)
      .set('exclude', excludeParameter.toString());
    return this.http
      .get<SearchAutoComplete>(this.env.elasticSearchEndpoint ? this.env.elasticSearchEndpoint + '/autocomplete' : 'elastic/autocomplete', {
        params,
      })
      .pipe(map(el => el.data.entries));
  }

  private searchByTerm(term: string): Observable<SearchV2 | null> {
    if (!this.termControl?.value) return of(null);
    if (!term || this.termControl?.value?.length < 2) {
      return of({} as SearchV2);
    }
    this.http = new HttpClient(this.handler);
    this.b2bSearchMappingService.getEnrolledBundleLinks();
    const excludeParameter = this.currentUser.isB2BUser ? this.b2bSearchMappingService.excludedB2cUuids : [];

    return this.http
      .get<ApiResponseModel<SearchV2Raw>>(this.env.elasticSearchEndpoint ? this.env.elasticSearchEndpoint + '/search' : '/elastic/search', {
        params: new HttpParams()
          .set('s', this.termControl?.value?.toString())
          .set('exclude', excludeParameter.toString())
          .set('group_by_chapters', 1),
      })
      .pipe(
        take(1),
        tap(() => {
          this.searchResultsLoading$$.next(false);
        }),
        map(res => {
          const coursesList = Object.values(res.data?.courses || {});
          const resCopy: SearchV2 = deepCopy(res.data) as any;
          resCopy.courses = coursesList;
          return resCopy;
        }),
        catchError(() => {
          return of(null);
        }),
      );
  }

  private isSearchResultsEmpty(searchResults: SearchV2 | null): boolean {
    if (!searchResults) {
      return true;
    }

    return (Object.keys(searchResults) as Array<keyof typeof searchResults>).every(key => {
      const value = searchResults[key];

      return !value?.length;
    });
  }

  private setRecentSearches(term: string | undefined): void {
    if (term && term.trim()) {
      if (this.recentSearches.size === 5 && !this.recentSearches.has(term.trim())) {
        const it = this.recentSearches.values();
        const first = it.next();
        this.recentSearches.delete(first.value);
      }
      this.recentSearches.add(term);
      this.recentSearches$$.next([...this.recentSearches]);
      this.localStorageService.setData(STORAGE_SEARCH, [...this.recentSearches]);
    }
  }

  private loadSearches(): void {
    const localSearches = this.localStorageService.getData(STORAGE_SEARCH);
    if (localSearches) {
      const parsedSearches = JSON.parse(localSearches);
      parsedSearches.forEach((el: string) => this.setRecentSearches(el));
    }
  }
}
