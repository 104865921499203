import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(
    readonly router: Router,
    @Inject(DOCUMENT) readonly document: Document,
  ) {}

  get window(): Window {
    return <Window>this.document.defaultView;
  }

  public jump(url: string, target: string = '_self'): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        resolve(!!this.window.open(url, target));
      } catch (e) {
        reject(e);
      }
    });
  }

  public external(url: string): boolean {
    return /^http(?:s)?:\/{2}\S+$/.test(url);
  }

  public redirect(url: string, extras?: RedirectionExtras): Promise<boolean> {
    const target = extras && extras.target;
    const link = '/redirect?url=' + url + (target ? '&=' + target : '');

    return this.router.navigateByUrl(link, extras);
  }

  public navigate(url: string, extras?: RedirectionExtras): Promise<boolean> {
    return this.external(url) ? this.redirect(url, extras) : this.router.navigateByUrl(url, extras);
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    const url = route.queryParamMap.get('url');
    if (!url) {
      return true;
    }
    if (this.external(url)) {
      const target = route.queryParamMap.get('target');
      if (!target) {
        return this.jump(url).then(() => false);
      }
      return this.jump(url, target).then(() => false);
    }
    return this.router.navigateByUrl(url).then(() => false);
  }
}

export interface RedirectionExtras extends NavigationExtras {
  target?: string;
}
