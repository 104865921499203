import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import {
  ApiResponseModel,
  CertificationCourseAbstractListingModel,
  CertificationCourseListingModel,
  CourseListingFilterModel,
  DropdownItem,
  Environment,
  ENVIRONMENT_TOKEN,
  PrintCertificateRequest,
  UserCertificateRequest,
} from '@lms/shared/models';
import { Router, UrlSerializer } from '@angular/router';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CertificationCourseService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private urlSerializer: UrlSerializer,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
  ) {}

  listing(filter: CourseListingFilterModel): Observable<ApiResponseModel<CertificationCourseAbstractListingModel>> {
    let params = new HttpParams()
      .set('pageNumber', filter.pageNumber?.toString() || '')
      .set('sort', filter.sort?.toString() || '')
      .set('pageSize', filter.pageSize?.toString() || '');
    if (typeof filter.query !== 'undefined' && filter.query !== null) {
      params = params.set('query', filter.query.toString());
    }
    if (typeof filter.category !== 'undefined' && filter.category !== null) {
      params = params.set('category', filter.category.toString());
    }
    if (typeof filter.level !== 'undefined' && filter.level !== null) {
      params = params.set('level', filter.level?.toString());
    }
    if (typeof filter.programId !== 'undefined' && filter.programId !== null) {
      params = params.set('programId', filter.programId?.toString());
    }
    return this.apiService
      .executeGet<ApiResponseModel<CertificationCourseListingModel>>('courses/certification_listing', params)
      .pipe(map((response: ApiResponseModel<CertificationCourseListingModel>) => this.mapCertificationCourseListingModel(response)));
  }

  createPrintCertificateRequest(
    userCertificateId: number,
    shippingAddress: PrintCertificateRequest,
    form: AbstractControl,
  ): Observable<{ data: UserCertificateRequest }> {
    const state = shippingAddress.shippingAddress.region;
    return this.apiService.executePost<{ data: UserCertificateRequest }>(
      'certificates/print_request',
      {
        userCertificateId,
        shippingAddress: {
          ...shippingAddress.shippingAddress,
          region: typeof state === 'string' ? state : state?.label || '',
          state: (typeof state === 'string' ? null : state?.value) || null,
        },
      },
      false,
      form,
    );
  }

  private mapCertificationCourseListingModel(
    response: ApiResponseModel<CertificationCourseListingModel>,
  ): ApiResponseModel<CertificationCourseAbstractListingModel> {
    return {
      status: response.status,
      data: {
        ...response.data,
        itemsList: response.data.courses,
      },
    };
  }
}
