import { Injectable } from '@angular/core';

export enum CustomBreakpointNames {
  extraLarge = 'extraLarge',
  smallPhone = 'smallPhone',
  phone = 'phone',
  tablet = 'tablet',
}

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {
  breakpoints: Record<string, CustomBreakpointNames> = {
    '(max-width: 480px)': CustomBreakpointNames.smallPhone,
    '(max-width: 768px)': CustomBreakpointNames.phone,
    '(max-width: 992px)': CustomBreakpointNames.tablet,
    '(min-width: 2400px)': CustomBreakpointNames.extraLarge,
  };

  getBreakpoints(): string[] {
    return Object.keys(this.breakpoints);
  }

  getBreakpointName(breakpointValue: string): string {
    return this.breakpoints[breakpointValue];
  }
}
