import { Inject, Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { lastValueFrom } from 'rxjs';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class RecaptchaService {
  private _recaptchaAction: string = '';

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
  ) {}

  set recaptchaAction(action: string) {
    this._recaptchaAction = action;
  }

  async loadToken(): Promise<string | undefined> {
    return this.environment.recaptchaClientKey ? await lastValueFrom(this.recaptchaV3Service.execute(this._recaptchaAction)) : '';
  }
}
