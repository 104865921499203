import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { ApiResponseModel, EnumsDictionaryModel } from '@lms/shared/models';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DictionaryService {
  enumsDictionaryModel: EnumsDictionaryModel = {} as EnumsDictionaryModel;
  private currentlyLoading = false;

  constructor(private apiService: ApiService) {}

  getEnumsDictionary(): Observable<EnumsDictionaryModel> {
    if (Object.keys(this.enumsDictionaryModel).length > 0) {
      return of(this.enumsDictionaryModel);
    }
    if (this.currentlyLoading) {
      return of(this.enumsDictionaryModel);
    }
    this.currentlyLoading = true;
    return this.apiService.executeGet<ApiResponseModel<EnumsDictionaryModel>>('dictionary/enums').pipe(
      map(res => res.data),
      tap(data => {
        this.enumsDictionaryModel = data;
        this.currentlyLoading = false;
      }),
    );
  }
}
