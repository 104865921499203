import { Injectable } from '@angular/core';
import {
  ApiResponseModel,
  AssessmentModelShow,
  CfiAssessmentAutocompleteListingModel,
  CfiAssessmentModel,
  CfiPersonalAssessmentModel,
} from '@lms/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CfiAssessmentService {
  constructor(private apiService: ApiService) {}

  autocompleteListing(): Observable<ApiResponseModel<CfiAssessmentAutocompleteListingModel>> {
    return this.apiService.executeGet<ApiResponseModel<CfiAssessmentAutocompleteListingModel>>(
      'admin/cfi_assessments/autocomplete_listing',
    );
  }

  listing(
    query: string = '',
    limit: number | null = null,
    excludePersonal: boolean = false,
  ): Observable<ApiResponseModel<AssessmentModelShow>> {
    let params = new HttpParams();
    if (query !== '') {
      params = params.set('query', query);
    }
    if (limit !== null) {
      params = params.set('limit', limit);
    }
    if (excludePersonal) {
      params = params.set('excludePersonal', excludePersonal);
    }
    return this.apiService.executeGet<ApiResponseModel<AssessmentModelShow>>('cfi_assessments/listing', params);
  }

  getCourseAssessment(courseId: number): Observable<ApiResponseModel<CfiAssessmentModel>> {
    const params = new HttpParams().set('courseId', courseId.toString());
    return this.apiService.executeGet<ApiResponseModel<CfiAssessmentModel>>('cfi_assessments/show', params);
  }

  getPersonalAssessment(): Observable<CfiPersonalAssessmentModel> {
    return this.apiService.executeGet<ApiResponseModel<CfiPersonalAssessmentModel>>('cfi_assessments/personal').pipe(map(res => res.data));
  }
}
