import { Injectable } from '@angular/core';
import { AdminStatisticsModel, EngagementModel } from '@lms/shared/models';
import { ApiResponseModel } from '@lms/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(private apiService: ApiService) {}

  getEngagement(): Observable<ApiResponseModel<EngagementModel>> {
    return this.apiService.executeGet<ApiResponseModel<EngagementModel>>('statistics/engagement');
  }
}
