import { Inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel, CourseWithLessonNotesModel, Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { pluck } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CourseLessonNoteService {
  constructor(
    private apiService: ApiService,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
    private router: Router,
  ) {}

  getCourseLessonNotes(courseUrl: string): Observable<CourseWithLessonNotesModel> {
    return this.apiService
      .executeGet<ApiResponseModel<CourseWithLessonNotesModel>>(`courses/${courseUrl}/course_lesson_notes`)
      .pipe(pluck('data'));
  }

  getCourseLessonNotesDownloadUrl(courseUrl: string): string {
    return `${this.environment.apiUrl}learning_notes/export?courseUrl=${courseUrl}`;
  }

  removeCourseLessonNote(learningNoteUuid: string): Observable<void> {
    return this.apiService.executeDelete<void>(`learning_notes/${learningNoteUuid}`);
  }
}
