import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BundleReviewFacadeService } from './domain/facade/bundle-review-facade.service';
import { BundleReviewService } from './services/bundle-review.service';
import { BundleEffects } from './store/bundle.effects';
import { reducer } from './store/bundle.reducer';

export const BUNDLE = {
  featureName: 'dashboard-program-not-enrolled',
};

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(BUNDLE.featureName, reducer), EffectsModule.forFeature([BundleEffects])],
  providers: [BundleReviewService, BundleReviewFacadeService],
})
export class BundleModule {}
