import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { Router, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class RouterEventService {
  // save the previous route
  public previousRoutePath = new BehaviorSubject<string>('');

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.previousRoutePath.next('');

    this.router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((event: any[]) => {
        this.previousRoutePath.next(event[0].urlAfterRedirects);
      });
  }
}
