import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyLoaderService {
  public loadExternalScript(url: string, id?: string): Promise<any> {
    return new Promise(resolve => {
      const styleElement = document.createElement('script');
      styleElement.src = url;
      styleElement.type = 'text/javascript';
      if (id) styleElement.id = id;
      styleElement.onload = resolve;
      document.head.appendChild(styleElement);
    });
  }

  public loadExternalScriptBody(body: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      scriptElement.text = body;
      scriptElement.type = 'text/javascript';
      document.head.appendChild(scriptElement);
    });
  }

  public loadExternalStyles(styleUrl: string): Promise<any> {
    return new Promise(resolve => {
      const styleElement = document.createElement('link');
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.href = styleUrl;
      styleElement.onload = resolve;
      document.head.appendChild(styleElement);
    });
  }
}
