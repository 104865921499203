import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { ApiResponseModel, CourseReviewListingModel } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class CourseReviewService {
  constructor(private apiService: ApiService) {}

  getRandomReviews(courseId: string | number): Observable<ApiResponseModel<CourseReviewListingModel>> {
    const params = new HttpParams().set('courseId', courseId.toString()).set('limit', (40).toString());
    return this.apiService.executeGet<ApiResponseModel<CourseReviewListingModel>>('course_reviews/latest_listing', params);
  }
}
