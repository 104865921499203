import { Injectable } from '@angular/core';
import { ApiResponseModel, CategoryAutocompleteListingModel, TopicListingModel } from '@lms/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CategoryService {
  constructor(private apiService: ApiService) {}

  autocompleteListing(financeDomainId: number | null = null): Observable<ApiResponseModel<CategoryAutocompleteListingModel>> {
    const params = financeDomainId !== null ? new HttpParams().set('financeDomainId', financeDomainId) : new HttpParams();
    return this.apiService.executeGet<ApiResponseModel<CategoryAutocompleteListingModel>>('categories/autocomplete_listing', params);
  }

  listing(resourceType: string): Observable<ApiResponseModel<TopicListingModel[]>> {
    const params = new HttpParams().set('resourceType', resourceType);
    return this.apiService.executeGet<ApiResponseModel<TopicListingModel[]>>('categories/listing', params);
  }
}
