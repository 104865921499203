import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { FeeScenario, PricingContextEnum, PricingPlanEnum, PricingPlanModel } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class RouteBuilderService {
  // --- enrollments start ---
  enrollLinkQueryParams(pricingPlan: undefined | null | PricingPlanModel): NavigationExtras {
    return { queryParams: this.toQueryParams(pricingPlan) };
  }

  toQueryParams(pricingPlan: undefined | null | PricingPlanModel, maxioRemittance?: boolean): PricingPlanQueryParams {
    return maxioRemittance ? { pricingPlanId: pricingPlan?.id, isManualVerification: maxioRemittance } : { pricingPlanId: pricingPlan?.id };
  }

  enrollLink(pricingPlan: undefined | PricingPlanModel, courseUrl: string): string[] {
    if (pricingPlan?.plan === PricingPlanEnum.FREE) {
      return ['/courses', 'enroll', courseUrl, 'free', 'confirm'];
    }
    return ['/courses', 'enroll', courseUrl, 'payment', 'payment'];
  }

  enrollProgramLink(pricingPlan: undefined | PricingPlanModel, bundleUrl: string): string[] {
    if (pricingPlan?.plan === PricingPlanEnum.FREE) {
      return ['/programs', 'enroll', bundleUrl, 'free', 'confirm'];
    }
    return ['/programs', 'enroll', bundleUrl, 'payment', 'payment'];
  }

  payPrintCertificateFeeLink(bundleUrl: string): string[] {
    return ['/programs', 'fee', bundleUrl, FeeScenario[FeeScenario.printCertificateFee], 'payment'];
  }

  payPrintCertificateFeeLinkQueryParams(
    pricingPlanId: undefined | null | number | string,
    userCertificateRequestIds: string | null,
  ): NavigationExtras {
    return { queryParams: { pricingPlanId, rids: userCertificateRequestIds } };
  }

  payExamFeeLink(bundleUrl: string): string[] {
    return ['/programs', 'fee', bundleUrl, FeeScenario[FeeScenario.examFee], 'payment'];
  }

  payExamFeeLinkQueryParams(pricingPlan: undefined | null | PricingPlanModel): NavigationExtras {
    return { queryParams: this.toQueryParams(pricingPlan) };
  }

  courseDetailsPageLink(courseUrl: string): string[] {
    return ['/course/', courseUrl];
  }

  programDetailsPageLink(url: string): string[] {
    return ['/', url];
  }

  // --- enrollments end ---

  // --- pricing start ---
  addPricingPlanLink(courseId: number, bundleId: number, context: PricingContextEnum): string[] {
    if (context === PricingContextEnum.COURSE) {
      return ['/courses/course/pricing', String(courseId), 'pricing_plan', String(0)];
    }
    return ['/bundles/bundle', String(bundleId), 'pricing', String(0)];
  }

  editPricingPlanLink(pricingPlan: PricingPlanModel, context: PricingContextEnum): (string | number)[] {
    if (context === PricingContextEnum.COURSE) {
      return ['/courses/course/pricing', pricingPlan.courseId, 'pricing_plan', pricingPlan.id];
    }
    return ['/bundles/bundle', pricingPlan.bundleId, 'pricing', pricingPlan.id];
  }

  listPricingPlansLink(pricingPlan: PricingPlanModel, context: PricingContextEnum): string[] {
    if (context === PricingContextEnum.COURSE) {
      return ['/courses/course/pricing', String(pricingPlan.courseId)];
    }
    return ['/bundles/bundle', String(pricingPlan.bundleId), 'pricing'];
  }

  // --- pricing end ---
}

export interface PricingPlanQueryParams {
  pricingPlanId?: number;
  isManualVerification?: boolean;
}
