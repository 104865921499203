export class ApiErrorStatusEnum {
  public static readonly AUTH_SESSION_EXPIRED_EXCEPTION = 'AUTH_SESSION_EXPIRED_EXCEPTION';
  public static readonly AUTH_NOT_AUTHORIZED_EXCEPTION = 'AUTH_NOT_AUTHORIZED_EXCEPTION';
  public static readonly AUTH_INVALID_CREDENTIALS_EXCEPTION = 'AUTH_INVALID_CREDENTIALS_EXCEPTION';
  public static readonly AUTH_LOGIN_BLOCKED_EXCEPTION = 'AUTH_LOGIN_BLOCKED_EXCEPTION';
  public static readonly AUTH_ACCESS_DENIED_EXCEPTION = 'AUTH_ACCESS_DENIED_EXCEPTION';
  public static readonly AUTH_COMPANY_ACCESS_DENIED_EXCEPTION = 'AUTH_COMPANY_ACCESS_DENIED_EXCEPTION';
  public static readonly AUTH_ENROLLMENT_EXPIRED_EXCEPTION = 'AUTH_ENROLLMENT_EXPIRED_EXCEPTION';
  public static readonly AUTH_INVALID_SYSTEM_EXCEPTION = 'AUTH_INVALID_SYSTEM_EXCEPTION';
  public static readonly VALIDATION_EXCEPTION = 'VALIDATION_EXCEPTION';
  public static readonly AUTH_TWO_FACTOR_CODE_NEEDED_EXCEPTION = 'AUTH_TWO_FACTOR_CODE_NEEDED_EXCEPTION';
  public static readonly AUTH_TWO_FACTOR_CODE_INVALID_EXCEPTION = 'AUTH_TWO_FACTOR_CODE_INVALID_EXCEPTION';
  public static readonly DOMAIN_COURSE_SET_STATUS_EXCEPTION = 'DOMAIN_COURSE_SET_STATUS_EXCEPTION';
  public static readonly NOT_FOUND_EXCEPTION = 'NOT_FOUND_EXCEPTION';
  public static readonly PAYMENTS_INVOICE_PAYMENT_FAILED_EXCEPTION = 'PAYMENTS_INVOICE_PAYMENT_FAILED_EXCEPTION';
  public static readonly AUTH_THINKIFIC_USER_NOT_CONFIRMED_EMAIL_EXCEPTION = 'AUTH_THINKIFIC_USER_NOT_CONFIRMED_EMAIL_EXCEPTION';
  public static readonly AUTH_RESET_PASSWORD_NOT_NEEDED_EXCEPTION = 'AUTH_RESET_PASSWORD_NOT_NEEDED_EXCEPTION';
  public static readonly AUTH_RESET_PASSWORD_TOKEN_HAS_EXPIRED_EXCEPTION = 'AUTH_RESET_PASSWORD_TOKEN_HAS_EXPIRED_EXCEPTION';

  public static getMessages(errorCode: string, errorDescription: string[]): string[] {
    // TODO: move all validation texts into frontend
    if (errorCode === this.VALIDATION_EXCEPTION) {
      if (typeof errorDescription !== 'undefined' && errorDescription.length !== 0) {
        return errorDescription;
      } else {
        return ['Validation error'];
      }
    }
    if (errorCode === this.DOMAIN_COURSE_SET_STATUS_EXCEPTION) {
      if (errorDescription && typeof errorDescription !== 'undefined' && errorDescription.length !== 0) {
        return errorDescription;
      }
    }
    if (errorCode === this.PAYMENTS_INVOICE_PAYMENT_FAILED_EXCEPTION) {
      if (errorDescription && typeof errorDescription !== 'undefined' && errorDescription.length !== 0) {
        return errorDescription;
      }
    }

    switch (errorCode) {
      case this.AUTH_SESSION_EXPIRED_EXCEPTION:
        return ['Session Expired'];
      case this.AUTH_NOT_AUTHORIZED_EXCEPTION:
        return [''];
      case this.AUTH_INVALID_CREDENTIALS_EXCEPTION:
        return ['Invalid Login or Password'];
      case this.AUTH_LOGIN_BLOCKED_EXCEPTION:
        return ['Login blocked. Please try again later.'];
      case this.AUTH_ACCESS_DENIED_EXCEPTION:
      case this.AUTH_COMPANY_ACCESS_DENIED_EXCEPTION:
        return errorDescription !== null ? errorDescription : ['Access Denied'];
      case this.AUTH_ENROLLMENT_EXPIRED_EXCEPTION:
        return errorDescription !== null ? errorDescription : ['Enrollment Expired'];
      case this.AUTH_INVALID_SYSTEM_EXCEPTION:
        return ['Invalid System Domain'];
      case this.AUTH_TWO_FACTOR_CODE_NEEDED_EXCEPTION:
        return ['Enter 2FA code'];
      case this.AUTH_TWO_FACTOR_CODE_INVALID_EXCEPTION:
        return ['Invalid 2FA Code'];
      case this.AUTH_THINKIFIC_USER_NOT_CONFIRMED_EMAIL_EXCEPTION:
        return ['Sorry, there are some issues'];
      case this.NOT_FOUND_EXCEPTION:
        return ['Not Found'];
      case this.AUTH_RESET_PASSWORD_NOT_NEEDED_EXCEPTION:
        return errorDescription !== null ? errorDescription : ['Activation Link Sent'];
      case this.AUTH_RESET_PASSWORD_TOKEN_HAS_EXPIRED_EXCEPTION:
        return errorDescription !== null ? errorDescription : ['Reset Password Token Has Expired'];
      default:
        if (typeof errorDescription !== 'undefined' && errorDescription !== null && errorDescription.length !== 0) {
          return errorDescription;
        } else {
          return ['Internal Server Error'];
        }
    }
  }
}
