import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ApiResponseModel, PricingPlanModel, UserEvent } from '@lms/shared/models';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LogService {
  constructor(private apiService: ApiService) {}

  programCheckoutPageVisited(pricingPlan: PricingPlanModel): Observable<ApiResponseModel<any>> {
    const params = new HttpParams().set('pricingPlanId', pricingPlan.id.toString());
    return this.apiService.executeGet<ApiResponseModel<any>>('log/checkout_page', params);
  }

  linkClickLogEvent(obj: UserEvent): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>('log/user_event', obj);
  }
}
