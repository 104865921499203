import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AdminFileModel, ApiResponseModel, UploadFileCreateModel, UploadFileModel } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class AdminFileService {
  constructor(private apiService: ApiService) {}

  createFile(contentCode: string, file: File): Observable<ApiResponseModel<UploadFileCreateModel>> {
    const params: FormData = new FormData();
    params.append('file', file, file.name);
    params.append('fileTypeCode', contentCode);
    return this.apiService.executePost<ApiResponseModel<UploadFileCreateModel>>('files/create', params, true);
  }

  editFile(file: UploadFileModel): Observable<ApiResponseModel<AdminFileModel>> {
    const params = {
      uuid: file.uuid,
      name: file.name,
    };
    return this.apiService.executePost<ApiResponseModel<AdminFileModel>>('admin/files/update', params);
  }
}
