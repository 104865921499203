import { Injectable } from '@angular/core';
import { ApiResponseModel, LatestAchievementsListingModel, UserAchievementsListingModel } from '@lms/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AchievementService {
  constructor(private apiService: ApiService) {}

  latestAchievements(): Observable<ApiResponseModel<LatestAchievementsListingModel>> {
    return this.apiService.executeGet<ApiResponseModel<LatestAchievementsListingModel>>('achievements/latest_listing');
  }

  userAchievements(): Observable<ApiResponseModel<UserAchievementsListingModel>> {
    return this.apiService.executeGet<ApiResponseModel<UserAchievementsListingModel>>('achievements/listing');
  }
}
