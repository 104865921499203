import { Injectable } from '@angular/core';

import { SidebarService } from '../sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class EnableSidebarGuard {
  constructor(private sidebar: SidebarService) {}
  canActivate(): boolean {
    this.sidebar.enabled$.next(true);
    return true;
  }

  canDeactivate(): boolean {
    this.sidebar.enabled$.next(true);
    return true;
  }
}
