import { Injectable } from '@angular/core';
import { TaxResidenceModel } from '@lms/shared/models';

type CountryCodeType = 'CA' | 'US' | 'PL' | 'RU';

type CountryCodeToZipCodeType = {
  [key in CountryCodeType]: (zipCode: string) => string;
};

const REMOVE_ALL_NON_DIGIT_NON_UPPERCASE_CHARS = /[^\dA-Z]/g;
const ADD_WHITE_SPACE_AFTER_THIRD_CHAR = /(.{3})/g;
const REMOVE_ALL_NON_DIGIT_CHARS = /\D/g;
const ADD_DASH_AFTER_SECOND_CHAR = /(.{2})/;
const REMOVE_ALL_CHARS_AFTER_FIFTH_CHAR = /^(.{5}).*$/;
const REMOVE_ALL_CHARS_AFTER_SIXTH_CHAR = /^(.{6}).*$/;
const ZIP_CODE_MAX_LENGTH_BY_COUNTRY = {
  CA: 7,
  US: 5,
  PL: 6,
  RU: 6,
};

@Injectable({ providedIn: 'root' })
export class TaxResidenceFormattingService {
  private countryCodeToZipFunction: CountryCodeToZipCodeType = {
    CA: this.formatCAZipCode,
    US: this.formatUSZipCode,
    PL: this.formatPLZipCode,
    RU: this.formatRUZipCode,
  };

  public getMaxZipCodeLength(state: string | undefined): number {
    return ZIP_CODE_MAX_LENGTH_BY_COUNTRY[state as CountryCodeType] || 10;
  }

  public formatZip(taxResidence: TaxResidenceModel): TaxResidenceModel {
    if (!taxResidence.zipCode || !taxResidence.countryCode) {
      return taxResidence;
    }

    return this.isOfCountryCodeType(taxResidence.countryCode)
      ? {
          ...taxResidence,
          zipCode: this.countryCodeToZipFunction[taxResidence.countryCode](taxResidence.zipCode),
        }
      : {
          ...taxResidence,
          zipCode: this.removeFormatting(taxResidence.zipCode),
        };
  }

  private isOfCountryCodeType(value: string): value is CountryCodeType {
    return ['CA', 'US', 'PL', 'RU'].includes(value);
  }

  private removeFormatting(zipCode: string): string {
    return zipCode.replace(REMOVE_ALL_NON_DIGIT_NON_UPPERCASE_CHARS, '');
  }

  private formatCAZipCode(zipCode: string): string {
    const zip = zipCode
      .toUpperCase()
      .replace(REMOVE_ALL_NON_DIGIT_NON_UPPERCASE_CHARS, '')
      .replace(ADD_WHITE_SPACE_AFTER_THIRD_CHAR, '$1 ');

    return zip.length > 5 ? zip.trim() : zip;
  }

  private formatUSZipCode(zipCode: string): string {
    return zipCode.replace(REMOVE_ALL_NON_DIGIT_CHARS, '').replace(REMOVE_ALL_CHARS_AFTER_FIFTH_CHAR, '$1');
  }

  private formatPLZipCode(zipCode: string): string {
    const zip = zipCode.replace(REMOVE_ALL_NON_DIGIT_CHARS, '').replace(REMOVE_ALL_CHARS_AFTER_FIFTH_CHAR, '$1');

    return zip.length > 1 ? zip.replace(ADD_DASH_AFTER_SECOND_CHAR, '$1-') : zip;
  }

  private formatRUZipCode(zipCode: string): string {
    return zipCode.replace(REMOVE_ALL_NON_DIGIT_CHARS, '').replace(REMOVE_ALL_CHARS_AFTER_SIXTH_CHAR, '$1');
  }
}
