import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppTitleService } from '../app-title.service';

@Injectable({ providedIn: 'root' })
export class TitleResolverService {
  constructor(private titleService: AppTitleService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.titleService.setTitle(route.data.title);
    return of(true);
  }
}
