import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RouteParamsService {
  static parseNumber(value: string | null): number | null {
    if (value === null) {
      return null;
    }
    return parseInt(value, 10);
  }
}
