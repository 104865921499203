import { Injectable } from '@angular/core';
import { ApiResponseModel, BundleModel, LearningPathsWrapperModel, SpecializationWrapperModel } from '@lms/shared/models';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpecializationService {
  constructor(protected http: ApiService) {}

  getSpecialisations(): Observable<BundleModel[]> {
    return this.http
      .executeGet<ApiResponseModel<SpecializationWrapperModel>>('specializations')
      .pipe(map((response: ApiResponseModel<SpecializationWrapperModel>) => response.data.specializations || []));
  }

  getLearningPaths(): Observable<BundleModel[]> {
    return this.http
      .executeGet<ApiResponseModel<LearningPathsWrapperModel>>('learningpaths')
      .pipe(map((response: ApiResponseModel<LearningPathsWrapperModel>) => response.data.learningPaths || []));
  }
}
