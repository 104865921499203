import { Injectable } from '@angular/core';

import { SidebarService } from '../sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class DisableSidebarGuard {
  constructor(private sidebar: SidebarService) {}
  canActivate(): boolean {
    this.sidebar.enabled$.next(false);
    return true;
  }
}
