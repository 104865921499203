import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdminVideoGetStatsModel,
  AdminVideoListingFilterModel,
  AdminVideoListingModel,
  AdminVideoModel,
  AdminVideoShowModel,
  ApiResponseModel,
} from '@lms/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AdminVideoService {
  defaultPlayerId = 'ALJ3XQCI';

  constructor(private apiService: ApiService) {}

  listing(filter: AdminVideoListingFilterModel): Observable<ApiResponseModel<AdminVideoListingModel>> {
    let params = new HttpParams().set('pageNumber', filter.pageNumber.toString()).set('pageSize', filter.pageSize.toString());
    if (filter.sort !== null) {
      params = params.set('sort', filter.sort.toString());
    }
    if (typeof filter.courseId !== 'undefined' && filter.courseId !== null) {
      params = params.set('courseId', filter.courseId.toString());
    }
    if (typeof filter.videoName !== 'undefined' && filter.videoName !== null) {
      params = params.set('videoName', filter.videoName.toString());
    }

    return this.apiService.executeGet<ApiResponseModel<AdminVideoListingModel>>('admin/videos/listing', params);
  }

  find(uuid: string): Observable<ApiResponseModel<AdminVideoShowModel>> {
    const params = new HttpParams().set('uuid', uuid.toString());

    return this.apiService.executeGet<ApiResponseModel<AdminVideoShowModel>>('admin/videos/show', params);
  }

  getStats(uuid: string): Observable<ApiResponseModel<AdminVideoGetStatsModel>> {
    const params = new HttpParams().set('uuid', uuid.toString());

    return this.apiService.executeGet<ApiResponseModel<AdminVideoGetStatsModel>>('admin/videos/stats', params);
  }

  create(video: AdminVideoModel, fileUuid: string): Observable<ApiResponseModel<AdminVideoShowModel>> {
    video.uuid = this.apiService.generateUuid();
    const params = {
      ...video,
      fileUuid,
    };

    return this.apiService.executePost<ApiResponseModel<AdminVideoShowModel>>('admin/videos/create', params);
  }

  updateThumbnail(video: AdminVideoModel): Observable<ApiResponseModel<AdminVideoShowModel>> {
    const params = {
      uuid: video.uuid,
      imageUuid: video.thumbnail?.uuid,
    };

    return this.apiService.executePost<ApiResponseModel<AdminVideoShowModel>>('admin/videos/update_thumbnail', params);
  }

  update(uuid: string, name: string, fileUuid: string | undefined): Observable<ApiResponseModel<AdminVideoShowModel>> {
    const params = {
      name,
      uuid,
      fileUuid,
    };

    return this.apiService.executePost<ApiResponseModel<AdminVideoShowModel>>('admin/videos/update', params);
  }

  listByName(videoName: string): Observable<ApiResponseModel<AdminVideoListingModel>> {
    const params = new HttpParams().set('videoName', videoName);

    return this.apiService.executeGet<ApiResponseModel<AdminVideoListingModel>>('admin/videos/autocomplete_listing', params);
  }

  delete(uuid: string): Observable<ApiResponseModel<null>> {
    const params = new HttpParams().set('uuid', uuid);

    return this.apiService.executeDelete<ApiResponseModel<null>>('admin/videos/delete', params);
  }
}
