import { Inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiResponseModel, CloudFileListingItemsModel, Environment, ENVIRONMENT_TOKEN, UploadFileCreateModel } from '@lms/shared/models';
import { Observable } from 'rxjs';

interface RequestCloudFileListingItemsModel {
  accessToken: string;
  directory: string | null;
}

@Injectable({ providedIn: 'root' })
export class CloudFileService {
  constructor(
    private apiService: ApiService,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
  ) {}

  oauthLoginUrl(provider: string): string {
    return new URL('/api/cloud_files/oauth_connect/' + provider, this.environment.apiUrl).toString();
  }

  listingItems(provider: string, accessToken: string, directory: string | null): Observable<ApiResponseModel<CloudFileListingItemsModel>> {
    const params = {
      accessToken,
      directory: null,
    } as RequestCloudFileListingItemsModel;
    if (typeof directory !== 'undefined' && directory !== null) {
      params.directory = directory.toString();
    }
    return this.apiService.executePost<ApiResponseModel<CloudFileListingItemsModel>>('cloud_files/listing_items/' + provider, params);
  }

  storeImage(
    provider: string,
    accessToken: string,
    fileTypeCode: string,
    fileId: string,
  ): Observable<ApiResponseModel<UploadFileCreateModel>> {
    const params = { accessToken, fileTypeCode, fileId };
    return this.apiService.executePost<ApiResponseModel<UploadFileCreateModel>>('cloud_files/store_image/' + provider, params);
  }

  storeFile(
    provider: string,
    accessToken: string,
    fileTypeCode: string,
    fileId: string,
  ): Observable<ApiResponseModel<UploadFileCreateModel>> {
    const params = { accessToken, fileTypeCode, fileId };
    return this.apiService.executePost<ApiResponseModel<UploadFileCreateModel>>('cloud_files/store_file/' + provider, params);
  }
}
