import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  ApiResponseModel,
  CapturedOrderMessage,
  FiUpgrade,
  PaymentMethodAddModel,
  PaymentMethodChangeContextEnum,
  PaymentMethodModel,
  PaymentProviderEnum,
  PreviewPayment,
  PricingPlanModel,
  SetupIntentModel,
  TaxResidenceModel,
  TransactionModel,
} from '@lms/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  public paymentMethodAdded$ = new BehaviorSubject<PaymentMethodAddModel>({} as PaymentMethodAddModel);

  constructor(private apiService: ApiService) {}

  paymentMethodAdded(paymentMethodAddModel: PaymentMethodAddModel): void {
    if (
      paymentMethodAddModel.context === PaymentMethodChangeContextEnum.CHANGE_ENROLLMENT_PAYMENT_METHOD &&
      paymentMethodAddModel.orderUuid
    ) {
      this.updateSubscriptionPaymentMethod(paymentMethodAddModel.orderUuid, paymentMethodAddModel.paymentMethod).subscribe(() => {
        this.paymentMethodAdded$.next(paymentMethodAddModel);
      });
    }
    if (paymentMethodAddModel.context === PaymentMethodChangeContextEnum.CHANGE_ORDER_PAYMENT_METHOD && paymentMethodAddModel.orderUuid) {
      this.updateOrderPaymentMethod(paymentMethodAddModel.orderUuid, paymentMethodAddModel.paymentMethod).subscribe(() => {
        this.paymentMethodAdded$.next(paymentMethodAddModel);
      });
    }
    if (paymentMethodAddModel.context === PaymentMethodChangeContextEnum.ADD_NEW) {
      this.paymentMethodAdded$.next(paymentMethodAddModel);
    }
  }

  updateSubscriptionPaymentMethod(orderUuid: string, paymentMethod: PaymentMethodModel): Observable<ApiResponseModel<null>> {
    const params = {
      orderUuid,
      paymentMethodId: paymentMethod.id,
      paymentProvider: paymentMethod.provider,
    };
    return this.apiService.executePost<ApiResponseModel<null>>('payments/update_subscription_payment_method', params);
  }

  updateOrderPaymentMethod(orderUuid: string, paymentMethod: PaymentMethodModel): Observable<ApiResponseModel<null>> {
    const params = {
      orderUuid,
      paymentMethodId: paymentMethod.id,
    };
    return this.apiService.executePost<ApiResponseModel<null>>('payments/update_order_payment_method', params);
  }

  upgradeToFi(): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>('orders/upgrade');
  }

  previewUpgradeToFi(): Observable<FiUpgrade> {
    return this.apiService.executeGet<ApiResponseModel<any>>('orders/upgrade/preview_cost').pipe(map(res => res.data));
  }

  updateTaxResidence(paymentId: number, form: AbstractControl): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>(
      `payments/${paymentId}/update_payment_method_tax_residence`,
      form.getRawValue(),
      false,
      form,
    );
  }

  setupIntent(): Observable<ApiResponseModel<SetupIntentModel>> {
    return this.apiService.executePost<ApiResponseModel<SetupIntentModel>>('payments/setup_intent');
  }

  confirmPaymentMethod(
    paymentMethodId: string,
    makeDefault: boolean,
    countryCode: string,
    state: string,
    region: string,
    city: string,
    zipCode: string,
    name: string,
    form: AbstractControl,
  ): Observable<PaymentMethodModel> {
    const params: any = {
      paymentMethodId: paymentMethodId,
      isDefault: makeDefault,
      region: region,
      city: city,
      country: countryCode,
      zip: zipCode,
      name,
    };
    if (state) {
      params['state'] = state;
    }
    return this.apiService
      .executePost<ApiResponseModel<PaymentMethodModel>>('payments/confirm_payment_method', params, false, form)
      .pipe(map(response => response.data));
  }

  makeDefault(paymentMethodId: number, paymentProvider: PaymentProviderEnum): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>('payments/choose_default', {
      paymentMethodId,
      paymentProvider,
    });
  }

  deletePaymentMethod(paymentMethodId: number, paymentProvider: PaymentProviderEnum): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>('payments/delete_payment_method', {
      paymentMethodId,
      paymentProvider,
    });
  }

  cancelSubscription(
    orderUuid: string,
    feedback?: any, // #TODO: provide proper model
    from?: AbstractControl,
  ): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>(
      'payments/cancel_subscription',
      {
        orderUuid,
        ...feedback,
      },
      false,
      from,
    );
  }

  reactivateSubscription(orderUuid: string): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>(`orders/${orderUuid}/reactivate`);
  }

  renewSubscriptionDiscount(): Observable<ApiResponseModel<null>> {
    return this.apiService.executeGet<ApiResponseModel<null>>('payments/renew_subscription_discount');
  }

  retryFailedCharge(orderUuid: string): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>('payments/retry_charge', {
      orderUuid,
    });
  }

  previewUpcomingInvoice(
    pricingPlanId: number,
    taxResidence?: TaxResidenceModel | null,
    couponCode?: string,
    migrate?: boolean,
    form?: AbstractControl,
  ): Observable<PreviewPayment> {
    let params = new HttpParams().set('pricingPlanId', pricingPlanId.toString());

    if (taxResidence?.countryCode?.length) {
      params = params.append('country', taxResidence?.countryCode);
    }
    if (taxResidence?.zipCode?.length) {
      params = params.append('zip', taxResidence?.zipCode);
    }

    if (taxResidence?.city) {
      params = params.append('city', taxResidence?.city);
    }

    if (taxResidence?.region) {
      params = params.append('region', taxResidence?.region);
    }

    if (taxResidence?.stateCode) {
      params = params.append('state', taxResidence?.stateCode);
    }

    if (migrate) {
      params = params.append('migrate', 1);
    }

    if (couponCode) {
      params = params.append('couponCode', couponCode);
    }
    return this.apiService
      .executeGet<ApiResponseModel<PreviewPayment>>('payments/preview_checkout_price', params, form)
      .pipe(pluck('data'));
  }

  createOrder(
    taxResidence: TaxResidenceModel,
    pricingPlan: PricingPlanModel,
    couponCode: null | string = null,
    city: string,
    region: string,
    state: string | null,
  ): Observable<TransactionModel> {
    const requestBody = {
      pricingPlanId: pricingPlan.id,
      country: taxResidence.countryCode,
      zip: taxResidence.zipCode,
      city: city || taxResidence.city,
      region: region || taxResidence.region,
      couponCode: couponCode || undefined,
      state: state || taxResidence.stateCode || undefined,
    } as any;

    return this.apiService.executePost<ApiResponseModel<TransactionModel>>('payments/checkout', requestBody).pipe(pluck('data'));
  }

  createMaxioPaymentMethod(options: MaxioOptions): Observable<PaymentMethodModel> {
    const requestBody = {
      country: options.taxResidence.countryCode,
      zip: options.taxResidence.zipCode,
      name: options.name,
      city: options.city || options.taxResidence.city,
      region: options.region || options.taxResidence.region,
      state: options.state || options.taxResidence.stateCode || undefined,
      isDefault: options.isDefault,
      token: options.token,
    } as any;

    return this.apiService
      .executePost<ApiResponseModel<PaymentMethodModel>>('payments/create_payment_method', requestBody)
      .pipe(pluck('data'));
  }

  captureOrder(orderId: string, payerId: string, subscriptionId: string): Observable<CapturedOrderMessage> {
    return this.apiService
      .executePost<ApiResponseModel<CapturedOrderMessage>>('payments/capture', {
        orderId,
        payerId,
        subscriptionId,
      })
      .pipe(pluck('data'));
  }

  getFiData(url = 'full-immersion-subscription'): Observable<any> {
    return this.apiService.executeGet(`bundles/pricing_plans?bundleUrl=${url}`);
  }
}

export interface MaxioOptions {
  token: string;
  taxResidence: TaxResidenceModel;
  name: string;
  city: string;
  region: string;
  state: string | null;
  isDefault: boolean;
}
