import { Inject, Injectable } from '@angular/core';
import { DatadogService } from './datadog.service';
import { Environment, ENVIRONMENT_TOKEN, UserModel } from '@lms/shared/models';
import { CurrentUserStorage } from '@lms/shared/storages';
import { generateId } from '@lms/shared/utils';

type Context = { [x: string]: any };
type LogUser = Pick<UserModel, 'id' | 'username' | 'email'>;

@Injectable({
  providedIn: 'root',
})
export class LoggerService extends DatadogService {
  public user: LogUser | null = null;
  public tabId: string = generateId();

  constructor(
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
    private currentUser: CurrentUserStorage,
  ) {
    super(environment);

    this.subscribeOnUser();
  }

  public debug(message: string, context?: Context | string): void {
    if (!this.initialized) {
      return;
    }

    this.datadog.logger.debug(message, this.buildMessageContext(context));
  }

  public info(message: string, context?: Context | string): void {
    if (!this.initialized) {
      return;
    }

    this.datadog.logger.info(message, this.buildMessageContext(context));
  }

  public warn(message: string, context?: Context | string): void {
    if (!this.initialized) {
      return;
    }

    this.datadog.logger.warn(message, this.buildMessageContext(context));
  }

  public error(message: string, context?: Context | string): void {
    if (!this.initialized) {
      return;
    }

    this.datadog.logger.error(message, this.buildMessageContext(context));
  }

  private subscribeOnUser(): void {
    this.currentUser.currentUser$.subscribe((user: UserModel | null) => {
      this.user = user
        ? {
            id: user.id,
            username: user.username,
            email: user.email,
          }
        : null;
    });
  }

  private buildMessageContext(context?: Context | string): Context {
    return {
      user: this.user,
      error: context,
    };
  }
}
