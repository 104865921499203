import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export interface Violation {
  code: string;
  path: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  form!: AbstractControl | null;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
  ) {}

  public static mapViolations(err: unknown, form: AbstractControl): void {
    if (!(err instanceof HttpErrorResponse)) {
      return;
    }
    form.setErrors({});
    if (err.status === 400) {
      const validationErrors = err.error?.status?.desc?.reduce(
        (acc: { [key: string]: Array<string> }, { path: pathname, message }: Violation | { path: string; message: string }) => {
          pathname = pathname ? pathname : '_default';
          if (!acc[pathname]) {
            acc[pathname] = [];
          }
          acc[pathname].push(message);

          return acc;
        },
        {},
      );

      Object.keys(validationErrors).forEach(prop => {
        const formControl = form.get(prop);
        if (formControl) {
          // activate the error message
          formControl.setErrors({
            serverError: validationErrors[prop], // TODO validationErrors[prop] can be a collection!
          });
        } else {
          form.setErrors({
            ...form.errors,
            serverError: validationErrors[prop],
          });
        }
      });
    }
  }

  public executeGet<T>(uri: string, params?: HttpParams, form?: AbstractControl): Observable<T> {
    if (form) {
      this.form = form;
    }
    return this.httpClient.get<T>(`${this.environment.apiUrl}${uri}`, { params });
  }

  public executePost<T>(uri: string, body?: Record<string, any>, reportProgress?: boolean, form?: AbstractControl): Observable<T> {
    let options = {};
    if (reportProgress) {
      options = {
        reportProgress: true,
        observe: 'events',
      };
    }

    if (form) {
      this.form = form;
    }
    return this.httpClient.post<T>(`${this.environment.apiUrl}${uri}`, body, options);
  }

  public executeBeacon(uri: string, body?: Record<string, any>): void {
    navigator.sendBeacon(`${this.environment.apiUrl}${uri}`, JSON.stringify(body));
  }

  public executePut<T>(uri: string, body?: Record<string, any>, reportProgress?: boolean): Observable<T> {
    let options = {};
    if (reportProgress) {
      options = {
        reportProgress: true,
        observe: 'events',
      };
    }
    return this.httpClient.put<T>(`${this.environment.apiUrl}${uri}`, body, options);
  }

  public executeDelete<T>(uri: string, params?: any): Observable<T> {
    return this.httpClient.delete<T>(`${this.environment.apiUrl}${uri}`, { params });
  }

  public generateUuid(): string {
    return uuidv4();
  }
}
