import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { BUNDLE } from '../bundle.constants';
import { BundleStoreState } from './bundle.reducer';

export const bundle = createFeatureSelector<BundleStoreState>(BUNDLE.featureName);
export const selectBundleReviews = createSelector(bundle, (state: BundleStoreState) => state.reviews);

@Injectable({ providedIn: 'root' })
export class BundleSelectors {
  bundleReviews$ = this.store.select(selectBundleReviews);

  constructor(private store: Store<BundleStoreState>) {}
}
