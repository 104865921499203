import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  ApiResponseModel,
  BundleDashboardListingModel,
  BundleModel,
  CourseDashboardListingModel,
  CourseDashboardModel,
  ProgramDashboardModel,
} from '@lms/shared/models';
import { CatalogService } from './catalog.service';
import { CurrentUserStorage } from '@lms/shared/storages';
import { StudentCourseService } from './student-course.service';

@Injectable({ providedIn: 'root' })
export class CatalogFacade {
  courses: CourseDashboardModel[] = [];
  coursesCompleted: CourseDashboardModel[] = [];
  programs: ProgramDashboardModel[] = [];
  programsCompleted: ProgramDashboardModel[] = [];

  courses$ = this.catalogService.getCourses().pipe(
    map(res => {
      this.courses = res.data.courses;
      return res.data.courses;
    }),
  );
  coursesCompleted$ = this.catalogService.getCourses(true).pipe(
    map((response: ApiResponseModel<CourseDashboardListingModel>) => {
      this.coursesCompleted = response.data.courses;
      return response.data.courses;
    }),
  );
  programs$ = this.catalogService.getPrograms().pipe(
    map((response: ApiResponseModel<BundleDashboardListingModel>) => {
      this.programs = response.data.bundles;
      return this.programs;
    }),
  );
  programsCompleted$ = this.catalogService.getPrograms(true).pipe(
    map((response: ApiResponseModel<BundleDashboardListingModel>) => {
      this.programsCompleted = response.data.bundles;
      return response.data.bundles;
    }),
  );

  constructor(
    private catalogService: CatalogService,
    private currentUserStorage: CurrentUserStorage,
    private studentCourseService: StudentCourseService,
  ) {}

  getOnlyEnrolledPrograms(programs: ProgramDashboardModel[]): ProgramDashboardModel[] {
    return programs.filter((model: ProgramDashboardModel) =>
      (this.currentUserStorage.getCurrentUser()?.enrolledBundles || []).includes(model.id),
    );
  }

  getOnlyEnrolledBundles(bundles: BundleModel[]): BundleModel[] {
    return bundles.filter((model: BundleModel) => (this.currentUserStorage.getCurrentUser()?.enrolledBundles || []).includes(model.id));
  }
}
