import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ApiResponseModel, FinanceDomainListingModel, FinanceDomainModel } from '@lms/shared/models';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FinanceDomainService {
  constructor(private apiService: ApiService) {}

  financeDomainAutocompleteListing(topic: string | null = null): Observable<FinanceDomainModel[]> {
    const params = topic !== null ? new HttpParams().set('topic', topic) : new HttpParams();
    return this.apiService
      .executeGet<ApiResponseModel<FinanceDomainListingModel>>('finance_domains/autocomplete_listing', params)
      .pipe(map(response => response.data.items));
  }
}
