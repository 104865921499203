import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { ApiResponseModel, QuizStatusModel } from '@lms/shared/models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class QuizService {
  constructor(private apiService: ApiService) {}

  getQuizStatus(enrollmentId: number, learningItemId: number): Observable<QuizStatusModel> {
    const params = new HttpParams().set('cust1', enrollmentId).set('cust2', learningItemId);
    return this.apiService.executeGet<ApiResponseModel<QuizStatusModel>>('course_player/quiz_status', params).pipe(map(res => res.data));
  }
}
