import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  public initialized = false;
  public datadog = datadogLogs;

  constructor(@Inject(ENVIRONMENT_TOKEN) public environment: Environment) {
    this.initialize();
  }

  private initialize(): void {
    if (!this.environment?.datadog) {
      return;
    }

    this.datadog.init(this.environment.datadog);
    this.initialized = true;
  }
}
