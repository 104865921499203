import { Injectable } from '@angular/core';
import { ThirdPartyLoaderService } from './third-party-loader.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { CurrentUserStorage } from '@lms/shared/storages';
import { Company, UserModel } from '@lms/shared/models';

declare const ChurnZero: any;

@Injectable({ providedIn: 'root' })
export class ChurnzeroService {
  serviceIsInitialized$ = new BehaviorSubject(false);

  constructor(
    private thirdPartyLoaderService: ThirdPartyLoaderService,
    public currentUser: CurrentUserStorage,
  ) {}

  run(): void {
    const user = this.currentUser.getCurrentUser();

    if (!user || !user.churnZero.isAllowed || !user.churnZero.apiUrl) {
      return;
    }

    this.thirdPartyLoaderService.loadExternalScript(user.churnZero.apiUrl).then(() => {
      ChurnZero.push(['setAppKey', user.churnZero.apiKey]);
      this.serviceIsInitialized$.next(true);
    });

    combineLatest([this.serviceIsInitialized$, this.currentUser.b2bUserCurrentCompany$]).subscribe(([serviceInitialized, company]: any) => {
      if (!serviceInitialized) {
        return;
      }

      if (company.tracked) {
        // change company settings when student changed dashboard
        ChurnZero.push(['setContact', company.uuid, user.churnZero.contactId]);
        return;
      }

      // if member dashboard is selected or not tracked company, get the first one from the list
      const defaultCompany = this.getFirstPossibleCompanyForTracking(user);
      if (defaultCompany) {
        ChurnZero.push(['setContact', defaultCompany.uuid, user.churnZero.contactId]);
      }
    });
  }

  getFirstPossibleCompanyForTracking(user: UserModel): Company | undefined {
    return user.companies.find(company => {
      return company.tracked === true;
    });
  }
}
