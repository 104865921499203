import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiResponseModel, ExamButtonData } from '@lms/shared/models';
import { HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExamService {
  private readonly api = inject(ApiService);

  startProgramExam(settings: { bundleUrl: string; isFinalExam: boolean }): Observable<ExamButtonData> {
    const params = new HttpParams()
      .set('isFinalExam', settings.isFinalExam ? 1 : 0)
      .set('bundleUrl', (settings.bundleUrl || '').toString());
    return this.api.executeGet<ApiResponseModel<any>>('exam/start_program', params).pipe(map(res => res.data.exam));
  }
  startCourseExam(settings: { courseUrl: string; examId: string }) {
    const params = new HttpParams().set('examId', settings.examId).set('courseUrl', (settings.courseUrl || '').toString());
    return this.api.executeGet<ApiResponseModel<any>>('exam/start_course', params);
  }
}
