import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable, pluck } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { ApiResponseModel, BundleModel, CourseModel } from '@lms/shared/models';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class B2bBundlesService {
  isLoading$ = new BehaviorSubject(true);

  constructor(private apiService: ApiService) {}

  getBundles(id: number): Observable<{ bundles: BundleModel[]; courses: CourseModel[] }> {
    this.isLoading$.next(true);
    const params = new HttpParams().set('id', id);
    return this.apiService
      .executeGet<ApiResponseModel<{ bundles: BundleModel[]; courses: CourseModel[] }>>('bundles/company/listing', params)
      .pipe(
        pluck('data'),
        finalize(() => this.isLoading$.next(false)),
      );
  }
}
